import Flame from "../icons/Flame";
import Lifespan from "../icons/Lifespan";
import Quality from "../icons/Quality";
import Recycle from "../icons/Recycle";

const advantages = [
    {
        title: "Product Quality",
        description:
            "Steel is a manufactured commodity and product quality is assured. As a material, steel is flawless and free of defect.",
        icon: <Quality />,
    },
    {
        title: "Recyclable",
        description:
            "Steel is the most recycled material on earth. making steel construction the ideal choice for the environment. It's the Green solution.",
        icon: <Recycle />,
    },
    {
        title: "Non-Flammable",
        description:
            "Steel doesn't catch fire. It can contain a fire, it can keep a fire out, and keep it from spreading. Steel is recognized to have an A-Fire Rating.",
        icon: <Flame />,
    },
    {
        title: "Long Lifespan",
        description:
            "Steel does not decompose. It remains sturdy and durable. Walls remain straight, floors are flat. Steel requires little to no maintenance.",
        icon: <Lifespan />,
    },
];

export default advantages;
