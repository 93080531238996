import { ReactElement } from "react";
import advantages from "./advantages";

import FeatureList from "@components/shared/featureList";
import colors from "@styles/variables/steelbuildingestimatesVariables.module.scss";

export default function Advantages(): ReactElement {
    return (
        <FeatureList
            featureList={advantages}
            mainTitle="Pre-Engineered Steel Building Solutions - The Advantages"
            mainDescription="The Benefits of Steel as a Material Are Well Known in the Construction Industry, They Include:"
            colors={{
                bgColor: colors.blue,
                mainTitleColor: "white",
                mainDescriptionColor: "white",
                titleColor: "white",
                descriptionColor: "white",
            }}
            classes={{
                mainSectionClasses:
                    "flex flex-col mx-auto px-4vw lg:px-4 py-10 md:py-36 -mt-16",
                mainTitleClasses:
                    "font-bold capitalize mb-4  mx-auto md:mb-6 text-2xl text-center  md:text-4xl ",
                mainDescriptionClasses:
                    "capitalize mb-12  mx-auto md:mb-20 text-lg text-center  md:text-2xl ",
                innerSectionClasses:
                    "grid grid-cols md:grid-cols-2 lg:grid-cols-4 text-center gap-16",
                oneBoxClasses: "flex flex-col justify-center items-center",
                iconClasses: "mx-auto mb-8",
                titleClasses: "text-xl md:text-2xl mb-2 font-semibold",
                descriptionClasses: "text-sm leading-5",
            }}
        />
    );
}
