import React from "react";

function Quality() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <path
                fill="#fff"
                d="M64.15 57.843l2.904 1.66.083-.167c4.066-5.642 6.224-12.365 6.224-19.419.083-9.045-3.32-17.51-9.627-23.817C57.427 9.71 49.045 6.14 40.083 6.14c-18.507 0-33.61 15.104-33.61 33.61 0 18.507 15.02 33.527 33.527 33.527 3.236 0 6.306-.415 9.211-1.327l.166-.083-.664-3.32-.248.083c-2.822.83-5.727 1.328-8.631 1.328-16.598 0-30.125-13.527-30.125-30.124C9.71 23.237 23.237 9.71 39.834 9.71s30.125 13.527 30.125 30.125c0 6.39-1.992 12.53-5.81 17.842v.166z"
            ></path>
            <path
                fill="#fff"
                d="M68.382 11.785C60.829 4.149 50.705 0 40.082 0c-3.734 0-7.303.498-10.704 1.493l-.167.083.582 3.07.248-.082c3.32-.913 6.722-1.41 10.042-1.41 20.332 0 36.93 16.514 36.93 36.929 0 20.416-16.598 36.847-36.93 36.847-20.415-.083-36.93-16.681-36.93-37.013 0-7.718 2.324-15.02 6.806-21.328l.165-.166-2.738-1.577-.083.166C2.49 23.734 0 31.702 0 40.082 0 62.076 17.925 80 39.917 80c22.075 0 40.084-17.925 40.084-39.917 0-10.705-4.067-20.747-11.619-28.3v.002z"
            ></path>
            <path
                fill="#fff"
                d="M28.381 36.1l-4.315 4.316 11.867 11.867L55.85 32.364l-4.316-4.315-15.601 15.602L28.38 36.1z"
            ></path>
        </svg>
    );
}

export default Quality;
