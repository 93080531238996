import React from "react";

function Recycle() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="85"
            height="80"
            fill="none"
            viewBox="0 0 85 80"
        >
            <path
                fill="#fff"
                d="M83.33 47.241c-4.126-6.829-12.355-20.498-12.355-20.498l-16.97 10.236 11.191 18.552h-7.481v-5.016L45.02 64.945 57.714 80v-4.89L68 75.133s3.857.247 5.577-3.465a3074.963 3074.963 0 008.98-15.637c1.078-1.897 3.256-4.675.775-8.79h-.001zM71.053 69.433c-1.2 2.434-3.296 2.414-3.296 2.414H54.83l-5.543-6.879 5.05-6.304H77.25c0 .001-3.862 6.717-6.197 10.77zm3.302-13.95l-5.317.028L58.535 38.1l11.313-6.822c1.71 2.855 8.43 13.993 10.453 17.35 3.063 5.075-2.691 6.812-5.947 6.857zm-55.164.04l3.825-6.43 4.33 2.606-5.815-18.317-19.444 3.11 4.189 2.521-5.323 8.802s-2.202 3.178.091 6.565A3252.547 3252.547 0 009.81 70.136c1.067 1.902 2.324 5.201 7.13 5.197 7.979-.012 23.935-.014 23.935-.014l-.017-19.818-21.667.021zm-8.903 8.711S6.525 57.46 4.257 53.37c-1.466-2.283-.368-4.068-.368-4.068l6.668-11.075 8.75-1.2 2.798 7.576-11.818 19.631zm7.028 7.791c-5.929.006-4.45-5.819-2.81-8.632l2.72-4.568 20.334-.018.012 13.208c-3.33-.004-16.337.007-20.257.01zM60.5 28.117l6.69-18.52-4.235 2.446-5.124-8.918S56.116-.34 52.044.027c-4.433 0-11.773.007-18.032.045-2.182.014-5.678-.481-8 3.723A23626.642 23626.642 0 0114.44 24.748l17.351 9.575L42.26 15.355l3.777 6.46-4.378 2.528 18.842 3.774zM30.497 29.84l-11.565-6.384c1.617-2.91 7.902-14.298 9.796-17.729 2.864-5.19 7.245-1.077 8.912 1.72l2.682 4.59-9.825 17.803zm8.449-26.491s7.748-.015 12.424-.018c2.708-.178 3.739 1.646 3.739 1.646l6.465 11.195-3.185 8.239-7.983-1.22L38.946 3.35z"
            ></path>
        </svg>
    );
}

export default Recycle;
