import React from "react";

function Flame() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="80"
            fill="none"
            viewBox="0 0 64 80"
        >
            <path
                fill="#fff"
                d="M28.7 8.4c6.2 5.8 9.1 13.3 9.2 23.7 0 2.8 2 5.2 4.7 5.6.3.1.6.1.9.1 3.1 0 5.5-2.4 5.6-5.5v-.8c0-.4 0-1.3.099-2.3 0-.9 1.1-1.3 1.7-.7 5.3 5.8 8.4 13.3 8.4 20.9 0 6.499-3.1 13.2-8.4 18.299-5.4 5.2-12.4 8.1-19.3 8.1s-13.9-3-19.3-8.1c-5.3-5.1-8.4-11.8-8.4-18.3 0-10.3 6.3-17.5 11.8-23.7 6.3-7.1 9.6-12.9 10.2-17.6.1-.8 1-1.1 1.6-.6l1.2.901zM22.6 0c-.7 0-1.3.3-1.7.9-.4.7.2 2.2.9 4.1 1.4 4.3-2.9 11.2-9 18.1C7.1 29.6 0 37.6 0 49.5 0 65.5 15.1 80 31.7 80s31.7-14.5 31.7-30.5c0-10.1-4.8-20.1-12.8-26.8l-1-.8c-.5-.4-1-.6-1.6-.6-.9 0-1.8.5-2.2 1.4-.3.7-.6 7.7-.6 8.9v.7c0 .9-.7 1.6-1.6 1.6h-.3c-.8-.1-1.4-.9-1.4-1.7-.1-11.999-3.7-20.4-10.5-26.7C28.3 2.6 24.8 0 22.6 0z"
            ></path>
            <path
                fill="#fff"
                d="M31.7 46.9l6.3 7.5.2.2c1.7 1.7 2.599 3.999 2.599 6.4 0 5-4.1 9.1-9.1 9.1s-9.1-4.1-9.1-9.1c0-2.4.9-4.7 2.6-6.4l.2-.2 6.3-7.5m0-4.6c-.8 0-1.6.3-2.2 1l-7.2 8.6c-2.3 2.4-3.8 5.6-3.8 9.2 0 7.2 5.9 13.1 13.1 13.1 7.2 0 13.1-5.9 13.1-13.1 0-3.6-1.4-6.8-3.8-9.2l-7.2-8.6c-.4-.7-1.2-1-2-1z"
            ></path>
        </svg>
    );
}

export default Flame;
